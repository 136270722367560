import React, { useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import s from './style.module.scss'
import Footer from '../components/Footer/Footer'

const MainProfile: React.FC = () => {
    const url = useLocation().pathname.split('/')[2];
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)

        !localStorage.getItem('token') && 
            navigate('/auth')
    }, [navigate]) 

    return (
        <>
            <div className={s.container + ' ' + s.block} style={{ height: '100%' }}>
                <aside className={s.aside}>
                    <Link className={s.routing_item} style={url === 'profile' ? { backgroundColor: 'var(--secondary-text-color)', color: '#fff' } : { backgroundColor: '#fff', color: 'var(--primary-color)' }} to="/personal-account/profile">ПРОФИЛЬ</Link>
                    <Link className={s.routing_item} style={url === 'reports' ? { backgroundColor: 'var(--secondary-text-color)', color: '#fff' } : { backgroundColor: '#FFF', color: 'var(--primary-color)' }} to="/personal-account/reports">Отчеты</Link>
                    <Link className={s.routing_item} style={url === 'feed' ? { backgroundColor: 'var(--secondary-text-color)', color: '#fff' } : { backgroundColor: '#FFF', color: 'var(--primary-color)' }} to="/personal-account/feed">Лента событий</Link>
                    {/* <div>
                    <Link to="/personal-account/propositions">Услуги</Link>
                </div> */}
                    <Link className={s.routing_item} style={url === 'about-us' ? { backgroundColor: 'var(--secondary-text-color)', color: '#fff' } : { backgroundColor: '#FFF', color: 'var(--primary-color)' }} to="/about-us">О Сервисе</Link>
                    <Link className={s.routing_item} style={url === 'contact-us' ? { backgroundColor: 'var(--secondary-text-color)', color: '#fff', width: 'max-content' } : { backgroundColor: '#FFF', color: 'var(--primary-color)' }} to="/contact-us">Связаться с нами</Link>
                    <Link className={s.routing_item} style={{ backgroundColor: 'var(--orange)', color: '#fff', width: 'max-content'}} to="/propositions">Мои предложения</Link>
                </aside>
                <div className={s.content_container} style={{ width: '100%' }}>
                    <Outlet />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MainProfile