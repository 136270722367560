import React from 'react'
import s from './style.module.scss'

const WhiteInfo: React.FC = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.content_container}>
                <div className={s.white_info_title}>
                    <h1>Одна заявка во все МФО</h1>
                    <p>Сотрудничая с несколькими микрофинансовыми организациями, мы можем предложить вам лучшие условия кредитования, основанные на нашем профессионализме и опыте в данной сфере.</p>
                </div>
            </div>

            <div className={s.content_container}>
                <div className={s.blue_info_content}>
                    <p>Чтобы получить доступ к нашей услуге, достаточно заполнить анкету на сайте. Все остальное мы сделаем за вас, экономя ваше время и силы. Мы принимаем клиентов без учета кредитной истории и текущих просрочек.</p>
                </div>
            </div>

            <div className={s.content_container}>
                <div className={s.white_info_title}>
                    <h1 style={{ textTransform: 'none' }}>Принцип работы сервиса:</h1>
                    <p>Заполнение анкеты: Начните с удобного заполнения анкеты на нашем сайте.</p>
                    <p>Подбор предложений: На основе ваших данных мы автоматически подберём самые выгодные предложения и будем работать напрямую с кредитными учреждениями, предоставляющими микрозаймы.</p>
                    <p>Отправка заявок: Ваша заявка будет направлена в те МФО, где шансы на одобрение наивысшие. Мы гарантируем соблюдение необходимых перерывов между отправками. После предварительного одобрения представители кредитных организаций свяжутся с вами для подтверждения согласия на займ и получения средств.</p>
                    <p>Отслеживание статуса: В разделе «Отчёты» вашего личного кабинета вы сможете видеть список МФО, куда были отправлены ваши заявки, а также другую важную информацию о процессе.</p>
                    <p>Отмена заявки: Если вы уже получили займ и решили больше не пользоваться услугами нашего сервиса, в любое время можете отменить обработку своей заявки в разделе <span>«Отказаться от услуг».</span></p>
                </div>
            </div>

            <div className={s.content_container}>
                <div className={s.reviews_block}>
                    <div className={s.white_info_title}>
                        <h1>Отзывы</h1>
                    </div>
                    <div className={s.reviews_content}>
                        <div className={s.reviews_item}>
                            <div className={s.reviews_item_img}>
                                <img src={require('../../assets/svg/review.svg').default} alt="review" />
                            </div>
                            <div className={s.reviews_item_content}>
                                <div className={s.reviews_item_name}>
                                    <h3>Алина</h3>
                                </div>
                                <div className={s.reviews_item_text}>
                                    <p>Быстрое оформление, подбор и одобрение, деньги получила в день!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhiteInfo