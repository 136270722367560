import React, { useEffect, useState } from 'react'
import s from '../../page/style.module.scss'
import Footer from '../../components/Footer/Footer'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReCAPTCHA from 'react-google-recaptcha'
import Modal from '../../components/Modal/Modal'

const ContactInfo: React.FC<{ utm: boolean }> = ({ utm }) => {

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [patronymic, setPatronymic] = useState('')
    const [phone, setPhone] = useState('')
    const [birthdate, setBirthdate] = useState<Date | null>(null);
    const [email, setEmail] = useState('')
    const navigate = useNavigate()

    const oid = useSelector((state: RootState) => state.oid)
    const dispatcher = useDispatch()

    const [know, setKnow] = useState(false)
    const [agreement, setAgreement] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    // Validations
    const pattern = /\D/g;
    const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputNumbersValue = onPhoneInput(e)

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] === '9') inputNumbersValue = '+' + inputNumbersValue
            let firstSymbols = (inputNumbersValue[0] === '8') ? '+7' : '+7'
            let formattedInputValue = firstSymbols + ' '
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
            }
            setPhone(formattedInputValue)
        }

        function onPhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
            return e.target.value.replace(pattern, '')
        }
    }
    const [formattedDate, setFormattedDate] = useState<string>('');
    useEffect(() => {
        if (birthdate) {
            const formatted = birthdate.toISOString().split('T')[0];
            setFormattedDate(formatted);
        }
    }, [birthdate]);

    const handleBirthdateChange = (date: Date | null) => {
        setBirthdate(date);
    };

    const [emailError, setEmailError] = useState(false);
    const emailIsValid = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    const [nameError, setNameError] = useState(false);
    const [surnameError, setSurnameError] = useState(false);
    const [patronymicError, setPatronymicError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        if (!emailIsValid(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        setEmail(email);
    };

    useEffect(() => {
        (!oid && !localStorage.getItem('oid')) && window.location.replace('/')
    }, [oid])

    // Requests
    const PASSPORT_DATA = process.env.REACT_APP_PASSPORT_DATA as string

    const handleAddContactInfo = async () => {
        setIsLoading(true)
        const formattedPhoneNumber = phone.replace(/\D+/g, '').replace(/^8/, '+7');
        await axios.post(PASSPORT_DATA, {
            phone_number: formattedPhoneNumber,
            visitor_oid: oid,
            firstname: name,
            lastname: patronymic,
            middlename: surname,
            birthdate: formattedDate,
            email: email,
        })
            .then(response => utm ? navigate('/passport?utm_medium=cpa') : navigate('/passport'))
            .catch(error => {
                if (error.response && error.response.status === 409) {
                    navigate('/auth');
                } else {
                    setIsLoading(false)
                    console.log(error)
                }
            })
        dispatcher({ type: 'ADD_PHONE', payload: phone })
        dispatcher({ type: 'ADD_NAME', payload: name })

        localStorage.setItem('phone', formattedPhoneNumber)
        localStorage.setItem('name', name)
    }

    const [verified, setVerified] = useState<boolean>(false);
    const CAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string

    const handleRecaptchaChange = (value: string | null) => {
        if (value) {
            setVerified(true);
        } else {
            setVerified(false);
        }
    };

    const [openModal, setOpenModal] = useState<boolean>(false)

    return (
        <>
            {
                openModal && <Modal setter={setOpenModal}/>
            }
            <div className={s.container + ' ' + s.contact_page} style={{ height: '100%', paddingBottom: '24px', padding: utm ? '0' : '24px' }}>
                <div className={s.title}>
                    <h1>КОНТАКТНЫЕ ДАННЫЕ</h1>
                </div>
                <div className={s.content}>
                    <form method='post' onSubmit={e => {
                        e.preventDefault()
                        handleAddContactInfo()
                    }}>
                        <div className={s.input_field} style={nameError ? { border: '1px solid red' } : {}}>
                            <label htmlFor="name">Имя</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder=""
                                onChange={(e) => {
                                    setName(e.target.value)
                                    e.target.value !== '' ? setNameError(false) : setNameError(true)
                                }}
                                value={name}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setNameError(false) : setNameError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.input_field} style={surnameError ? { border: '1px solid red' } : {}}>
                            <label htmlFor="surname">Фамилия</label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                placeholder=""
                                onChange={(e) => {
                                    setSurname(e.target.value)
                                    e.target.value !== '' ? setSurnameError(false) : setSurnameError(true)
                                }}
                                value={surname}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setSurnameError(false) : setSurnameError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.input_field} style={patronymicError ? { border: '1px solid red' } : {}}>
                            <label htmlFor="patronymic">Отчество</label>
                            <input
                                type="text"
                                id="patronymic"
                                name="patronymic"
                                placeholder=""
                                onChange={(e) => {
                                    setPatronymic(e.target.value)
                                    e.target.value !== '' ? setPatronymicError(false) : setPatronymicError(true)
                                }}
                                value={patronymic}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setPatronymicError(false) : setPatronymicError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.input_field} style={phoneError ? { border: '1px solid red' } : {}}>
                            <label htmlFor="phone">Номер телефона</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder=""
                                onChange={(e) => {
                                    handleChangePhone(e)
                                    e.target.value.length >= 18 ? setPhoneError(false) : setPhoneError(true)
                                }}
                                value={phone}
                                onBlur={
                                    (e) => {
                                        e.target.value !== '' ? setPhoneError(false) : setPhoneError(true)
                                    }
                                }
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="birthdate">Дата рождения</label>
                            <DatePicker
                                selected={birthdate}
                                onChange={handleBirthdateChange}
                                dateFormat="dd.MM.yyyy"
                                id="birthdate"
                                name="birthdate"
                            />
                        </div>
                        <div className={s.input_field} style={emailError ? { border: '1px solid red' } : {}}>
                            <label htmlFor="email">Электронная почта</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder=""
                                onChange={(e) => handleEmailChange(e)}
                                value={email}
                            />
                        </div>
                        <div className={s.checkbox_field}>
                            <input type="checkbox" name='know' id='know' onChange={() => setKnow(!know)} checked={know} />
                            <label htmlFor="know">Я ознакомлен и согласен <span style={{cursor: 'pointer'}} onClick={() => setOpenModal(!openModal)}>со следующим</span></label>
                        </div>
                        <div className={s.checkbox_field}>
                            <input type="checkbox" name='agreement' id='agreement' onChange={() => setAgreement(!agreement)} checked={agreement} />
                            <label htmlFor="agreement">Даю согласие на <span>рекуррентные списания, хранение данных владельца банковской карты.</span> <p>Стоимость услуги - 1 996,00 RUB.</p></label>
                        </div>
                        <ReCAPTCHA
                            sitekey={CAPTCHA}
                            theme="light"
                            hl="ru"
                            size="normal"
                            onChange={handleRecaptchaChange}
                        />
                        <div className={s.form_btn} style={{ paddingTop: '8px' }}>
                            {
                                isLoading
                                    ? <div className="loading" />
                                    : <input disabled={!name || !surname || !patronymic || phone.length < 18 || !birthdate || !email || !know || !agreement || emailError || !verified} type="submit" value="Продолжить" />
                            }s
                        </div>
                    </form>
                </div>
            </div>
            {
                !utm &&
                <Footer />
            }
        </>

    )
}

export default ContactInfo