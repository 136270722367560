import Home from './Home';
import Authorisation from './Authorisation';
import AboutServicePage from './UserProfilePages/AboutServicePage';
import ContactUsPage from './ContactUsPage';
import PassportDataPage from './PassportDataPage';
import AddressDataPage from './AddressDataPage';
import NotFoundPage from './NotFoundPage';

import PropositionsPage from './PropositionsPage';
import FeedPage from './UserProfilePages/FeedPage';
import MainProfile from './MainProfile';
import PersonalAccount from './UserProfilePages/PersonalAccount';
import Reports from './/UserProfilePages/Reports';


import AdminMain from './AdminProfilePages/AdminMain';
import Dashboard from './AdminProfilePages/Dashboard';
import Statistics from './AdminProfilePages/Statistics';
import AutoFunnels from './AdminProfilePages/AutoFunnels';


export { AdminMain, Dashboard, Statistics, AutoFunnels, Reports, AboutServicePage, ContactUsPage, PropositionsPage, FeedPage, MainProfile, PassportDataPage, AddressDataPage, PersonalAccount, NotFoundPage, Home, Authorisation }