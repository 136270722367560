import React from 'react'
import s from '../style.module.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'

interface User {
  data: {
    number_phone: string,
    email: string,
    address: {
      country: string,
      region: string,
      city: string,
      street: string,
      house: string,
      housing: string,
      flat: string
    },
    passport: {
      reg_adress: {
        country: string,
        region: string,
        city: string,
        street: string,
        house: string,
        housing: string,
        flat: string
      },
      firstname: string,
      lastname: string,
      middlename: string,
      birthdate: string,
      birthplace: string,
      gender: string,
      passport_code: string
    }
  },
  status: string,
  code: number
}

const PersonalAccount: React.FC = () => {
  const GET_DATA = process.env.REACT_APP_USER as string
  const user = useSelector((state: any) => state.user)

  const getUserData = async (): Promise<User> => {
    const response = await axios.get(GET_DATA, {
      params: {
        phone_number: user.phone ? user.phone : localStorage.getItem('phone')
      }
    })
    return response.data as User;
  }

  const { data, isLoading, isError } = useQuery<User>('UserData', getUserData)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <>
      <div className={s.container} style={{ height: '100%', paddingBottom: '24px' }}>
        <div className={s.title}>
          <h1>ПРОФИЛЬ</h1>
        </div>
        <div className={s.content_container} >
          {/* Личные данные */}
          <div className={s.content + ' ' + s.profile} style={{ padding: '24px' }}>
            <div className={s.content_title}>
              <h2>ЛИЧНЫЕ ДАННЫЕ</h2>
            </div>
            <hr />
            <div className={s.content_data}>
              <div className={s.data_field}>
                <h4>ФИО</h4>
                <p>{`${data?.data?.passport?.middlename} ${data?.data?.passport?.firstname} ${data?.data?.passport?.lastname}`}</p>
              </div>
              <div className={s.data_field}>
                <h4>ДАТА РОЖДЕНИЯ</h4>
                <p>{data?.data.passport.birthdate}</p>
              </div>
              <div className={s.data_field}>
                <h4>ТЕЛЕФОН</h4>
                <p>{data?.data?.number_phone}</p>
              </div>
              <div className={s.data_field}>
                <h4>EMAIL</h4>
                <p>{data?.data?.email}</p>
              </div>
            </div>
          </div>
          {/* ПАСПОРТНЫЕ ДАННЫЕ */}
          <div className={s.content + ' ' + s.profile} style={{ padding: '24px' }}>
            <div className={s.content_title}>
              <h2>ПАСПОРТНЫЕ ДАННЫЕ</h2>
            </div>
            <hr />
            <div className={s.content_data}>
              <div className={s.data_field}>
                <h4>СЕРИЯ / НОМЕР</h4>
                <p>
                  {data?.data.passport?.passport_code && (
                    <>
                      {data.data.passport.passport_code.split(";")[0]} /{" "}
                      {data.data.passport.passport_code.split(";")[1]}
                    </>
                  )}
                </p>
              </div>
              <div className={s.data_field}>
                <h4>КОД ПОДРАЗДЕЛЕНИЯ</h4>
                <p>
                  {data?.data.passport?.passport_code && (
                    <>
                      {data.data.passport.passport_code.split(";")[2]}
                    </>
                  )}
                </p>
              </div>
              <div className={s.data_field}>
                <h4>ДАТА ВЫДАЧИ ПАСПОРТА</h4>
                <p>
                  {data?.data.passport?.passport_code && (
                    <>
                      {data.data.passport.passport_code.split(";")[3]}
                    </>
                  )}
                </p>
              </div>
              <div className={s.data_field}>
                <h4>МЕСТО РОЖДЕНИЯ</h4>
                <p>{data?.data.passport.birthplace}</p>
              </div>
            </div>
          </div>
          {/* АДРЕС РЕГИСТРАЦИИ */}
          <div className={s.content + ' ' + s.profile} style={{ padding: '24px' }}>
            <div className={s.content_title}>
              <h2>АДРЕС РЕГИСТРАЦИИ</h2>
            </div>
            <hr />
            <div className={s.content_data}>
              <div className={s.data_field}>
                <h4>РЕГИОН</h4>
                <p>{data?.data?.address.region}</p>
              </div>
              <div className={s.data_field}>
                <h4>НАСЕЛЕННЫЙ ПУНКТ</h4>
                <p>{data?.data?.address.city}</p>
              </div>
              <div className={s.data_field}>
                <h4>УЛИЦА</h4>
                <p>{data?.data?.address.street}</p>
              </div>
              <div className={s.data_field}>
                <h4>ДОМ, КВАРТИРА</h4>
                <p>{data?.data?.address.house}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PersonalAccount