import React, { useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

const data = [
  { time: '00:00', conversions: 0, expenses: 2, income: 3 },
  { time: '04:00', conversions: 3, expenses: 2, income: 3 },
  { time: '08:00', conversions: 4, expenses: 2, income: 3 },
  { time: '12:00', conversions: 3, expenses: 2, income: 3 },
  { time: '16:00', conversions: 4, expenses: 1, income: 3 },
  { time: '20:00', conversions: 3, expenses: 2, income: 3 },
]

const Statistics: React.FC = () => {
  const [dateRange, setDateRange] = useState('today')

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4 text-center">СТАТИСТИКА</h1>
      
      <div className="mb-4 relative">
        <select
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="today">Сегодня</option>
          <option value="yesterday">Вчера</option>
          <option value="week">Неделя</option>
        </select>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-4">
        {[
          { title: 'Конверсии', value: '12' },
          { title: 'Расход', value: '0,00 ₽' },
          { title: 'Доход', value: '0,00 ₽' },
          { title: 'Прибыль', value: '0,00 ₽' },
          { title: 'ROI', value: '0,00 ₽' }
        ].map((item, index) => (
          <div key={index} className="bg-orange-500 text-white rounded-lg shadow-md p-4">
            <h3 className="text-sm font-semibold mb-2">{item.title}</h3>
            <p className="text-2xl font-bold">{item.value}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="bg-orange-500 text-white rounded-lg shadow-md p-4">
          <h3 className="text-sm font-semibold mb-2">Уникальные клики</h3>
          <p className="text-4xl font-bold">40</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-lg font-semibold mb-2">Клики</h3>
          <div className="flex items-center justify-center">
            <div className="relative w-32 h-32">
              <svg viewBox="0 0 36 36" className="w-full h-full">
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#E2E8F0"
                  strokeWidth="2"
                />
                <path
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#4C51BF"
                  strokeWidth="2"
                  strokeDasharray="58, 100"
                />
                <text x="18" y="20" textAnchor="middle" fill="#4C51BF" fontSize="10">40/69</text>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4">
        <h3 className="text-lg font-semibold mb-4">График статистики</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="conversions" stroke="#8884d8" />
            <Line type="monotone" dataKey="expenses" stroke="#82ca9d" />
            <Line type="monotone" dataKey="income" stroke="#ffc658" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default Statistics