// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_why_we_content_block__ajY3y {
  border-top: 1px solid var(--gray);
  padding: var(--padding-inside-mobile) 0;
  display: flex;
  align-items: center;
  gap: var(--padding-outside-mobile);
}
.style_why_we_content_block__ajY3y .style_why_we_content_text__XzUXI p {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.style_why_we_content_block__ajY3y img {
  width: 45px;
  height: 45px;
}`, "",{"version":3,"sources":["webpack://./src/components/WhyWe/style.module.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,uCAAA;EACA,aAAA;EACA,mBAAA;EACA,kCAAA;AACJ;AACQ;EACI,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACZ;AAEI;EACI,WAAA;EACA,YAAA;AAAR","sourcesContent":[".why_we_content_block{\n    border-top: 1px solid var(--gray);\n    padding: var(--padding-inside-mobile) 0;\n    display: flex;\n    align-items: center;\n    gap: var(--padding-outside-mobile);\n    .why_we_content_text {\n        p{\n            text-transform: uppercase;\n            font-size: 12px;\n            font-weight: 400;\n            line-height: 15px;\n        }\n    }\n    img {\n        width: 45px;\n        height: 45px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"why_we_content_block": `style_why_we_content_block__ajY3y`,
	"why_we_content_text": `style_why_we_content_text__XzUXI`
};
export default ___CSS_LOADER_EXPORT___;
