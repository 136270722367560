import React from 'react'
import Header from '../containers/Header/Header'
import Main from '../containers/Main/Main'


const Home: React.FC = () => {
  return (
    <>
        <Header />
        <Main />
    </>
  )
}

export default Home