import React, { useState } from 'react'
import s from './style.module.scss'
import ReCAPTCHA from 'react-google-recaptcha'

const Unsubscribe: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const UNSUBSCRIBE = process.env.REACT_APP_UNSIBSCRIBE as string

    const pattern = /\D/g;
    const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputNumbersValue = onPhoneInput(e)

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] === '9') inputNumbersValue = '+' + inputNumbersValue
            let firstSymbols = (inputNumbersValue[0] === '8') ? '+7' : '+7'
            let formattedInputValue = firstSymbols + ' '
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
            }
            setPhoneNumber(formattedInputValue)
        }

        function onPhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
            return e.target.value.replace(pattern, '')
        }
    }
    const handleUnsubscribe = (e: React.FormEvent<HTMLFormElement>) => {

    }

    const [verified, setVerified] = useState<boolean>(false);
    const CAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string

    const handleRecaptchaChange = (value: string | null) => {
        if (value) {
            setVerified(true);
        } else {
            setVerified(false);
        }
    };
    return (
        <div className={s.container + ' ' + s.authorisation}>
            <div className={s.title}>
                <h1>ОТКАЗАТЬСЯ ОТ УСЛУГИ</h1>
            </div>
            <div className={s.content}>
                <form method='post' onSubmit={e => handleUnsubscribe(e)}>
                    <div className={s.input_field}>
                        <label htmlFor="login">Мобильный телефон</label>
                        <input
                            type="tel"
                            id="login"
                            name="login"
                            value={phoneNumber}
                            onChange={e => handleChangePhone(e)}
                            placeholder="+7 (___) ___ __ __"
                        />
                    </div>
                    <ReCAPTCHA
                        sitekey={CAPTCHA}
                        theme="light"
                        hl="ru"
                        size="normal"
                        onChange={handleRecaptchaChange}
                    />
                    <div className={s.form_btn}>
                        <input disabled={phoneNumber.length < 18 || !verified} type="submit" value="Отказаться от услуги" style={{ width: '80%' }} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Unsubscribe