import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import Nav from './components/Nav/Nav';
import ContactInfo from './containers/ContactInfo/ContactInfo';
import {
  Home,
  PropositionsPage,
  AboutServicePage,
  AddressDataPage,
  Authorisation,
  ContactUsPage,
  FeedPage,
  MainProfile,
  NotFoundPage,
  PassportDataPage,
  PersonalAccount,
  Reports,
  Dashboard,
  Statistics,
  AutoFunnels,
  AdminMain
} from './page';
import ShortPage from './page/ShortPage';
import Calculateing from './components/Calculateing/Calculateing';
import Unsubscribe from './page/Unsubscribe';
import Progress from './page/Progress';

const AppRoutes: React.FC<{ user: any, utmMedium: string | null }> = ({ user, utmMedium }) => {
  const getDashboardComponent = (user: { role: string | undefined }) => {
    switch (user?.role) {
      case 'admin':
        return <AdminMain />
      case 'user':
        return <NotFoundPage />
      default:
        return <NotFoundPage />
    }
  }

  return (
    <Routes>
      {utmMedium === 'cpa' ? (
        <Route path="*" element={<ShortPage utmMedium={utmMedium} />}>
          <Route index element={<Calculateing utm={true} />} />
          <Route path="contact-information" element={<ContactInfo utm={true} />} />
          <Route path="passport" element={<PassportDataPage utm={true} />} />
          <Route path="address" element={<AddressDataPage utm={true} />} />
          <Route path="accepting-decision" element={<Progress />} />
          <Route path="propositions" element={<PropositionsPage utm={true} />} />
        </Route>
      ) : (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Authorisation />} />
          <Route path="/admin/*" element={getDashboardComponent(user)}>
            <Route path="*/*" element={<NotFoundPage />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="auto-funnels" element={<AutoFunnels />} />
          </Route>
          <Route path="/personal-account/*" element={<MainProfile />}>
            <Route path="profile" element={<PersonalAccount />} />
            <Route path="reports" element={<Reports />} />
            <Route path="feed" element={<FeedPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="/about-us" element={<AboutServicePage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/passport" element={<PassportDataPage utm={false} />} />
          <Route path="/address" element={<AddressDataPage utm={false} />} />
          <Route path="/propositions" element={<PropositionsPage utm={false} />} />
          <Route path="/contact-information" element={<ContactInfo utm={false} />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="*" element={<NotFoundPage />} />
        </>
      )}
    </Routes>
  );
}

const App = () => {
  const user = {
    role: 'admin'
  }

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="App">
          <Nav />
          <AppRoutesWrapper user={user} />
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

const AppRoutesWrapper: React.FC<{ user: any }> = ({ user }) => {
  const location = useLocation();
  const utmMedium = new URLSearchParams(location.search).get('utm_medium');

  return <AppRoutes user={user} utmMedium={utmMedium} />;
}

export default App;