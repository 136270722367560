// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_valets_row__623re {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
}
.style_valets_row__623re .style_valet__IjpiH {
  border-radius: var(--border-radius-continer);
  background-color: var(--primary-text-color);
  padding: 11px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.style_valets_row__623re .style_valet__IjpiH img {
  width: 100px;
  height: 100px;
}

@media screen and (min-width: 1200px) {
  .style_valets_row__623re {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 16px;
    margin-bottom: 8px;
  }
  .style_valets_row__623re .style_valet__IjpiH {
    border-radius: var(--border-radius-continer);
    background-color: var(--light-gray) !important;
    padding: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .style_valets_row__623re .style_valet__IjpiH img {
    width: 100px;
    height: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Valets/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EAEA,QAAA;EACA,kBAAA;AAAJ;AACI;EACI,4CAAA;EACA,2CAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACR;AAAQ;EACI,YAAA;EACA,aAAA;AAEZ;;AAGA;EAEI;IACI,aAAA;IACA,WAAA;IACA,uBAAA;IACA,SAAA;IACA,kBAAA;EADN;EAEM;IACI,4CAAA;IACA,8CAAA;IACA,aAAA;IACA,WAAA;IACA,aAAA;IACA,uBAAA;EAAV;EACU;IACI,YAAA;IACA,aAAA;EACd;AACF","sourcesContent":[".valets_row {\n    display: flex;\n    justify-content: center;\n\n    gap: 8px;\n    margin-bottom: 8px;\n    .valet {\n        border-radius: var(--border-radius-continer);\n        background-color: var(--primary-text-color);\n        padding: 11px 0;\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        img {\n            width: 100px;\n            height: 100px;\n        }\n    }\n}\n\n@media screen and (min-width: 1200px) {\n    \n    .valets_row {\n        display: flex;\n        width: 100%;\n        justify-content: center;\n        gap: 16px;\n        margin-bottom: 8px;\n        .valet {\n            border-radius: var(--border-radius-continer);\n            background-color: var(--light-gray) !important;\n            padding: 12px;\n            width: 100%;\n            display: flex;\n            justify-content: center;\n            img {\n                width: 100px;\n                height: 100px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"valets_row": `style_valets_row__623re`,
	"valet": `style_valet__IjpiH`
};
export default ___CSS_LOADER_EXPORT___;
