import React, { useEffect, useState } from 'react'
import s from './style.module.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const Nav: React.FC = () => {

    const [isAuth, setIsAuth] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    const location = useLocation();
    const utmMedium = new URLSearchParams(location.search).get('utm_medium');

    useEffect(() => {
        if (isOpen) {
            document.body.style.position = 'fixed';
            document.body.style.top = '0';
            document.body.style.left = '0';
            document.body.style.width = '100%';
            document.body.style.height = '100%';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.left = '';
            document.body.style.width = '';
            document.body.style.height = '';
            document.body.style.overflow = '';
        }
    }, [isOpen]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setIsAuth(true)
        }
    }, [])

    return (
        <>
            <nav className={s.nav}>
                <div className={s.logo}>
                    {
                        utmMedium === 'cpa'
                            ? <img src={require('../../assets/svg/logo.svg').default} onClick={() => setIsOpen(false)} alt="logo" />
                            : <Link to='/'>
                                <img src={require('../../assets/svg/logo.svg').default} onClick={() => setIsOpen(false)} alt="logo" />
                            </Link>
                    }
                </div>
                <div className={s.links}>
                    {
                        utmMedium === 'cpa' ?
                            <div className={s.row_links} />
                            :
                            <div className={s.row_links}>
                                <a href="#whywe">Почему мы</a>
                                <Link to="/about-us">О сервисе</Link>
                                <a href="#faq">Вопросы-ответы</a>
                            </div>
                    }
                    {
                        isAuth &&
                        <Link to='/personal-account/profile'><img src={require('../../assets/svg/personal.svg').default} alt="ЛК" /></Link>
                    }
                    {
                        isAuth
                            ? <Link to='/' onClick={() => {
                                localStorage.removeItem('token')
                                setIsAuth(false)
                            }}><img src={require('../../assets/svg/logout.svg').default} alt="ЛК" /></Link>
                            : <Link to='/auth' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '8px' }}><img src={require('../../assets/svg/personal.svg').default} alt="ЛК" /><span> Войти</span></Link>
                    }
                    {
                        <div className={s.burger_menu}>
                            <img src={require('../../assets/svg/burger.svg').default} alt="menu" onClick={() => setIsOpen(!isOpen)} />
                        </div>
                    }
                </div>
            </nav>

            <div className={s.menu} style={{ height: isOpen ? '100vh' : 'auto', display: isOpen ? 'block' : 'none' }}>
                <ul>
                    {
                        isAuth ?
                            <>
                                <li>
                                    <Link to='/personal-account/profile' onClick={() => setIsOpen(!isOpen)}>ПРОФИЛЬ</Link>
                                </li>
                                <li>
                                    <Link to="/personal-account/reports" onClick={() => setIsOpen(!isOpen)}>Отчеты</Link>
                                </li>
                                <li>
                                    <Link to="/personal-account/feed" onClick={() => setIsOpen(!isOpen)}>Лента Событий</Link>
                                </li>
                                <li>
                                    <Link to="/about-us" onClick={() => setIsOpen(!isOpen)}>О сервисе</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us" onClick={() => setIsOpen(!isOpen)}>Связь с нами</Link>
                                </li>
                                <li>
                                    <button className={s.menu_btn} onClick={() => {
                                        navigate('/propositions')
                                        setIsOpen(!isOpen)
                                    }
                                    }>Мои предложения</button>
                                </li>
                            </> :
                            <>
                                <li>
                                    <Link to='/auth' onClick={() => setIsOpen(!isOpen)}>Войти</Link>
                                </li>
                                <li>
                                    <Link to="/#whywe" onClick={() => setIsOpen(!isOpen)}>Почему мы</Link>
                                </li>
                                <li>
                                    <Link to="/personal-account/about-us" onClick={() => setIsOpen(!isOpen)}>О сервисе</Link>
                                </li>
                                <li>
                                    <Link to="/#faq" onClick={() => setIsOpen(!isOpen)}>Вопросы-ответы</Link>
                                </li>
                            </>
                    }
                </ul>
            </div>
        </>
    )
}

export default Nav