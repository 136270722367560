// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_table__oxy7Z {
  overflow: auto;
  max-height: 400px;
}
.style_table__oxy7Z table {
  max-width: 800px;
  overflow: auto;
  overflow-y: auto;
}
.style_table__oxy7Z table thead {
  position: sticky;
  top: 0;
  background-color: #E0E0E0;
}
.style_table__oxy7Z table tbody {
  background-color: #fff;
}
.style_table__oxy7Z table thead th,
.style_table__oxy7Z table tbody td {
  color: #000;
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/containers/Table/style.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;AACJ;AACI;EACI,gBAAA;EACA,cAAA;EACA,gBAAA;AACR;AACQ;EACI,gBAAA;EACA,MAAA;EACA,yBAAA;AACZ;AAEQ;EACI,sBAAA;AAAZ;AAGQ;;EAEI,WAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AADZ","sourcesContent":[".table {\n    overflow: auto;\n    max-height: 400px;\n\n    table {\n        max-width: 800px;\n        overflow: auto;\n        overflow-y: auto;\n\n        thead {\n            position: sticky;\n            top: 0;\n            background-color: #E0E0E0;\n        }\n\n        tbody {\n            background-color: #fff;\n        }\n\n        thead th,\n        tbody td {\n            color: #000;\n            border: 1px solid #000;\n            padding: 10px;\n            text-align: left;\n            overflow: hidden;\n            text-overflow: ellipsis;\n            white-space: nowrap;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `style_table__oxy7Z`
};
export default ___CSS_LOADER_EXPORT___;
