import React from 'react'
import s from './style.module.scss'
import Table from '../../containers/Table/Table'

const Dashboard: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.title}>
          <h1>БАЗА КЛИЕНТОВ</h1>
        </div>
        <div className={s.content}>
          <div className={s.row}>
            <form action="get">
              <div className={s.input_field}>
                <label htmlFor="from">с</label>
                <input type="date" id="from" name="from" placeholder='Дата' />
              </div>
              <div className={s.input_field}>
                <label htmlFor="to">по</label>
                <input type="date" id="to" name="to" placeholder='Дата' />
              </div>
              <div className={s.input_field}>
                <select name="filter" id="filter">
                  <option value="#" defaultChecked>
                    Найти по
                  </option>
                </select>
              </div>
              <div className={s.input_field}>
                <input type="text" placeholder='Значение' />
              </div>
              <input type="submit" value={'Найти'}/>
            </form>
          </div>

          <div className={s.row + ' ' + s.row_btns}>
            <div className={s.left}>
              <button>Выгрузить базу</button>
            </div>
            <div className={s.right}>
              <button>Добавить клиента</button>
              <button>Удалить клиента</button>
            </div>
          </div>
        </div>
        <Table />
      </div>
    </div>
  )
}

export default Dashboard