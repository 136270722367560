// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_error_container__rj\\+nQ {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999;
  padding: 1em 2em;
  max-width: 400px;
  background-color: rgb(238, 70, 70);
  border-radius: 12px;
}
.style_error_container__rj\\+nQ h1 {
  font-size: 14px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/Error/style.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EAEA,gBAAA;EAEA,kCAAA;EACA,mBAAA;AADJ;AAGI;EACI,eAAA;EACA,gBAAA;AADR","sourcesContent":[".error_container {\n    position: fixed;\n    bottom: 15px;\n    right: 15px;\n    z-index: 999;\n    padding: 1em 2em;\n\n    max-width: 400px;\n\n    background-color: rgb(238, 70, 70);\n    border-radius: 12px;\n\n    h1{\n        font-size: 14px;\n        font-weight: 700;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_container": `style_error_container__rj+nQ`
};
export default ___CSS_LOADER_EXPORT___;
