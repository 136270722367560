// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_faq__k4ON9 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  width: 100%;
  border-radius: var(--border-radius-continer);
  cursor: pointer;
}
.style_faq__k4ON9 .style_faq_container__wG4d- {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: var(--padding-inside-mobile);
}
.style_faq__k4ON9 .style_faq_container__wG4d- .style_question__FQFDO h1 {
  transition: 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 400;
}
.style_faq__k4ON9 .style_faq_container__wG4d-:hover h1 {
  color: var(--secondary-text-color);
}
.style_faq__k4ON9 .style_faq_container__wG4d- .style_faq_logo__BKJL2 img {
  width: 16px;
  height: 16px;
}

.style_answer__tCUd8.style_animate__H\\+4PA {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}
.style_answer__tCUd8.style_show__77b8M {
  height: auto;
}
.style_answer__tCUd8.style_hide__MUZxz {
  height: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/FAQ/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;EACA,4CAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,qCAAA;AACJ;AAEM;EACE,4BAAA;EACA,eAAA;EACA,gBAAA;AAAR;AAMM;EACE,kCAAA;AAJR;AASM;EACE,WAAA;EACA,YAAA;AAPR;;AAeE;EACE,mCAAA;EACA,gBAAA;AAZJ;AAeE;EACE,YAAA;AAbJ;AAgBE;EACE,SAAA;AAdJ","sourcesContent":[".faq {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  background: #fff;\n  width: 100%;\n  border-radius: var(--border-radius-continer);\n  cursor: pointer;\n\n  .faq_container {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    padding: var(--padding-inside-mobile);\n\n    .question {\n      h1 {\n        transition: .3s ease-in-out;\n        font-size: 14px;\n        font-weight: 400;\n      }\n    }\n\n    &:hover {\n\n      h1 {\n        color: var(--secondary-text-color);\n      }\n    }\n\n    .faq_logo {\n      img {\n        width: 16px;\n        height: 16px;\n      }\n    }\n\n  }\n}\n\n.answer {\n  &.animate {\n    transition: height 0.5s ease-in-out;\n    overflow: hidden;\n  }\n\n  &.show {\n    height: auto;\n  }\n\n  &.hide {\n    height: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faq": `style_faq__k4ON9`,
	"faq_container": `style_faq_container__wG4d-`,
	"question": `style_question__FQFDO`,
	"faq_logo": `style_faq_logo__BKJL2`,
	"answer": `style_answer__tCUd8`,
	"animate": `style_animate__H+4PA`,
	"show": `style_show__77b8M`,
	"hide": `style_hide__MUZxz`
};
export default ___CSS_LOADER_EXPORT___;
