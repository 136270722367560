import React, { useState } from 'react'
import s from './style.module.scss'
// import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddressDataPage: React.FC<{ utm: boolean }> = ({ utm }) => {
    const [region, setRegion] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [street, setStreet] = useState<string>('')
    const [home, setHome] = useState<string>('')
    const [flat, setFlat] = useState<string>('')

    const PASSPORT_DATA = process.env.REACT_APP_PASSPORT_DATA as string
    // const userState = useSelector((state: any) => state.user)
    const navigate = useNavigate()

    const [concidence, setConcidence] = useState<boolean>(false)

    const handleSubmitAddress = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (concidence) {
            const factAddres = {
                country: "Россия",
                region,
                city,
                street,
                house: home,
                housing: null,
                flat
            }
            axios.patch(PASSPORT_DATA, {
                number_phone: localStorage.getItem('phone'),
                reg_country: "Россия",
                reg_region: region,
                reg_city: city,
                reg_street: street,
                reg_house: home,
                reg_housing: null,
                reg_flat: flat,
                ...factAddres
            })
        } else {
            axios.patch(PASSPORT_DATA, {
                number_phone: localStorage.getItem('phone'),
                reg_country: "Россия",
                reg_region: region,
                reg_city: city,
                reg_street: street,
                reg_house: home,
                reg_housing: null,
                reg_flat: flat,
            })
        }
        utm ? navigate('/accepting-decision?utm_medium=cpa') : navigate('/propositions')
    }

    const [regionError, setRegionError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [streetError, setStreetError] = useState(false)
    const [homeError, setHomeError] = useState(false)
    const [flatError, setFlatError] = useState(false)

    return (
        <>
            <div className={s.container + ' ' + s.container_contact}>
                <div className={s.title}>
                    <h1>АДРЕС РЕГИСТРАЦИИ</h1>
                </div>
                <div className={s.content}>
                    <form method='patch' onSubmit={e => handleSubmitAddress(e)}>
                        <div className={s.input_field} style={ regionError ? { border: '1px solid red' } : {} }>
                            <label htmlFor="region">Регион</label>
                            <input
                                type="text"
                                id="region"
                                name="region"
                                placeholder=""
                                onChange={(e) => {
                                    e.target.value !== '' ? setRegionError(false) : setRegionError(true)
                                    setRegion(e.target.value)
                                }
                                }
                                value={region}
                                onBlur={e => e.target.value === '' ? setRegionError(true) : setRegionError(false)}
                            />
                        </div>
                        <div className={s.input_field} style={ cityError ? { border: '1px solid red' } : {} }>
                            <label htmlFor="city">Населенный пункт</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                placeholder=""
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                                onBlur={e => e.target.value === '' ? setCityError(true) : setCityError(false)}
                            />
                        </div>
                        <div className={s.input_field} style={ streetError ? { border: '1px solid red' } : {} }>
                            <label htmlFor="street">Улица</label>
                            <input
                                type="text"
                                id="street"
                                name="street"
                                placeholder=""
                                onChange={(e) => setStreet(e.target.value)}
                                value={street}
                                onBlur={e => e.target.value === '' ? setStreetError(true) : setStreetError(false)}

                            />
                        </div>
                        <div className={s.input_field} style={ homeError ? { border: '1px solid red' } : {} }>
                            <label htmlFor="home">Дом</label>
                            <input
                                type="text"
                                id="home"
                                name="home"
                                placeholder=""
                                onChange={(e) => setHome(e.target.value)}
                                value={home}
                                onBlur={e => e.target.value === '' ? setHomeError(true) : setHomeError(false)}
                            />
                        </div>
                        <div className={s.input_field} style={ flatError ? { border: '1px solid red' } : {} }>
                            <label htmlFor="apartment">Квартира</label>
                            <input
                                type="text"
                                id="apartment"
                                name="apartment"
                                placeholder=""
                                onChange={e => setFlat(e.target.value)}
                                value={flat}
                                onBlur={e => e.target.value === '' ? setFlatError(true) : setFlatError(false)}
                            />
                        </div>
                        <div className={s.checkbox_container}>
                            <div className={s.checkbox_field}>
                                <input
                                    type="checkbox"
                                    id="matches"
                                    name="matches"
                                    onChange={e => setConcidence(!concidence)}
                                    checked={concidence}
                                />
                                <label htmlFor="matches">Совпадает с фактическим</label>
                            </div>
                        </div>
                        <div className={s.form_btn}>
                            <input disabled={!flat || !home || !street || !city || !region} type="submit" value="Продолжить" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddressDataPage