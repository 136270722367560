import React from 'react'
import s from '../style.module.scss'
import admin from './style.module.scss'
const AutoFunnels: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}  style={{ height: '95vh' }}>
        <div className={s.title}>
          <h1>АВТОМАТИЧЕСКИЕ ФУНКЦИИ</h1>
        </div>
        <div className={admin.blocks}>
          {/* upper */}
          <div className={admin.row}>
            <div className={admin.block}>
              <div className={admin.block_title}>
                <h1>Отжим трафика БАНКИ<br/>(Экпресс чето там)</h1>
              </div>
            </div>  
            <div className={admin.block}>
              <div className={admin.block_title}>
                <h1>Отжим трафика ЗАЙМЫ<br/>(Экпресс чето там)</h1>
              </div>
            </div>  
            <div className={admin.block}>
              <div className={admin.block_title}>
                <h1>Квалифицирующая воронка</h1>
              </div>
            </div>  
          </div>
          {/* lower */}
          <div className={admin.row}>
            <div className={admin.block}>
              <div className={admin.block_title}>
                <h1>Воронка 1EXP RU</h1>
              </div>
            </div>  
            <div className={admin.block}>
              <div className={admin.block_title}>
                <h1>Воронка Новая Займ<br/>(Общий поток)</h1>
              </div>
            </div>  
            <div className={admin.block}>
              <div className={admin.block_title}>
                <h1>Воронка Рейтинг<br/>(основ)</h1>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  )
}

export default AutoFunnels