const initialState = {
    phone: '',
    name: ''
}

const ADD_PHONE = "ADD_PHONE"
const ADD_NAME = "ADD_NAME"

const UserReduser = (state = initialState, action: { type: string, payload: string }) => {
    switch (action.type) {
      case ADD_PHONE:
        return { ...state, phone: action.payload };
      case ADD_NAME:
        return { ...state, name: action.payload };
      default:
        return state;
    }
  };
  
  export default UserReduser;