import React, { useEffect, useState } from 'react'
import s from './style.module.scss'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Error from '../Error/Errors';

interface UTM {
    utm: boolean
}

const Calculateing: React.FC<UTM> = ({utm}) => {

    const [progressMoney, setProgressMoney] = useState<number>(3000)
    const [progressDays, setProgressDays] = useState<number>(11)
    const [returnDate, setReturnDate] = useState<Date>(new Date())
    const [formattedTime, setFormattedTime] = useState('');

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    const CREATE_VISITOR = process.env.REACT_APP_CREATE_VISITOR as string

    const dispatcher = useDispatch()
    // const store = useSelector((state: any) => state)
    const navigate = useNavigate()

    useEffect(() => {
        const currentTime = new Date();
        currentTime.setMinutes(currentTime.getMinutes() + 15);
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        setFormattedTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
    }, []);

    const returningMoney = (): void => {
        let today: Date = new Date();
        setReturnDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() + (progressDays)))
    }

    const getIp = async (): Promise<string> => {
        try {
            const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error(error);
            return '';
        }
    };

    interface CreateVisitorResponse {
        data: {
            oid: string,
            credit_sum: number,
            credit_days: number,
            fingerprint: {
                ip: string,
                user_agent: string
            }
        },
        status: string
    }
    const handleCreateVisitor = async () => {
        setIsLoading(true)
        try {
            const ip = await getIp()
            const useragent = navigator.userAgent
            const data = {
                credit_sum: progressMoney,
                credit_days: progressDays,
                ip: ip,
                user_agent: useragent,
            }

            await axios.post<CreateVisitorResponse>(CREATE_VISITOR, data)
                .then(res => {
                    if (res.data.data.oid) {
                        dispatcher({ type: 'ADD_OID', payload: res.data.data.oid })
                        localStorage.setItem('oid', res.data.data.oid)
                        utm ? navigate('/contact-information?utm_medium=cpa'): navigate('/contact-information') 
                    } else {
                        console.error('Ответ сервера не содержит поле oid')
                    }
                })
                .catch(err => {
                    setIsLoading(false)
                    console.log(err)
                    setIsError(true)
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        returningMoney()
    }, [progressDays])

    return (
        <div className={s.calculateing_wrapper} style={{marginTop: utm ? '50px' : '0'}}>
            {isError ? <Error text="Произошла ошибка отправки запроса! Повторите попытку позже..." setter={setIsError} /> : null}
            <div className={s.header_title}>
                <div className={s.title}>
                    <h1>БЕСПЛАТНЫЙ<p>ЗАЕМ</p></h1>
                    <div className={s.timer_container}>
                        <h4>Деньги у Вас в</h4>
                        <div className={s.timer}> <img src={require('../../assets/svg/timer.svg').default} alt="timer" />{formattedTime}</div>
                    </div>
                </div>
                <div className={s.percent}>
                    <img src={require('../../assets/svg/percent.svg').default} alt="0%" />
                </div>
            </div>

            <div className={s.calculateing}>
                <div className={s.progressbars}>
                    <div className={s.progress_field}>
                        <progress id="money" max="49000"
                            value={progressMoney}
                        >{progressMoney}</progress>
                        <div className={s.progress_label} style={{
                            '--value': progressMoney,
                            '--max': 49000
                        } as any}>
                            {progressMoney} ₽
                        </div>
                        <input type="range" min="0" max="48500" step="100" value={progressMoney} onChange={e => setProgressMoney(Number(e.target.value) + 1500)} />
                        <div className={s.between}>
                            <span>1 500</span>
                            <span>50 000</span>
                        </div>
                    </div>
                    <div className={s.progress_field}>
                        <div className={s.progress_label} style={{
                            '--value': progressDays > 50 ? 50 : progressDays,
                            '--max': 50
                        } as any}>
                            {progressDays > 50 ? 50 : progressDays} дней
                        </div>
                        <progress id="days" max="50"
                            value={progressDays}
                        >{progressMoney}</progress>
                        <input type="range" min="0" max="50" step="1" value={progressDays} onChange={e => setProgressDays(Number(e.target.value) + 3)} />
                        <div className={s.between}>
                            <span>3</span>
                            <span>50</span>
                        </div>
                    </div>
                </div>
                <hr />
                <div className={s.calculateing_info}>
                    <div className={s.summ}>
                        <h2>Сумма</h2>
                        <span>{progressMoney}₽</span>
                    </div>
                    <div className={s.return_money}>
                        <h3>Вернуть</h3>
                        <span>{progressMoney}₽</span>
                    </div>
                    <div className={s.returning}>
                        <h3>Погасить до:</h3>
                        <span>{returnDate.toLocaleString("ru-RU", { day: "numeric", month: "numeric", year: "numeric" })}</span>
                    </div>
                    {
                        isLoading
                            ? <div style={{display: 'flex', justifyContent: 'center'}}><div className="loading"  style={{borderTop: "10px solid var(--light-color)"}}/></div>
                            : <button className={s.get_money} onClick={
                                handleCreateVisitor
                            }>Получить {progressMoney}₽</button>
                    }
                    <p className={s.low_info}>Всего 1999 руб. за доступ к сервису!</p>
                </div>
            </div>
        </div>
    )
}

export default Calculateing