import React, { useState } from 'react'
import s from './style.module.scss'

interface FAQProps {
    id: number;
    question: string;
    answer: string;
    key: number;
  }

const FAQ :React.FC<FAQProps> = ({id, question, answer}) => {
    const [open, setOpen] = useState<null | number>(null)

    const handleClick = () => {
        setOpen((prevOpen) => (prevOpen === id ? null : id));
    };
  return (
    <div className={s.faq} onClick={handleClick}>
        <div className={s.faq_container}>
            <div className={s.faq_logo}><img src={require('../../assets/svg/question.svg').default} alt="faq" /></div>
            <div className={s.question}>
                <h1>{question}</h1>
                {
                    open === id && 
                    <div className={`${s.answer} ${s.animate} ${open === id ? s.show : s.hide}`}>
                        <p>{answer}</p>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default FAQ