import React from 'react'
import s from './style.module.scss'

const Table :React.FC = () => {
  return (
    <div className={s.table}>
        <table>
            <thead>
                <tr>
                    <th>№</th>
                    <th>ФИО</th>
                    <th>Телефон</th>
                    <th>Электронная почта</th>
                    <th>Регион</th>
                    <th style={{width: '50px', whiteSpace: 'wrap'}}>Населенный пункт</th>
                    <th>Адрес</th>
                    <th>Дата рождения</th>
                    <th>Серия номер паспорта</th>
                    <th>Код подразделения</th>
                    <th>Дата выдачи</th>
                    <th>Место рождения</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>Глебов Олег Викторович</td>
                    <td>"+7(976)567-50-98"</td>
                    <td>svahblj@gmail.com</td>
                    <td>Москва</td>
                    <td>Москва</td>
                    <td>ул. Академика Янгеля, д. 4, кв. 134</td>
                    <td>01.08.1978</td>
                    <td>4567 786789</td>
                    <td>500-134</td>
                    <td>14.09.2014</td>
                    <td>Московская обл., г. Жуковский</td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}

export default Table