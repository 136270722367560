import React from 'react'
import s from './style.module.scss'
import Documents from '../../components/Documents/Documents'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
    return (
        <>
            <div className={s.contacts}>
                <div className={s.desktop_footer}>
                    <div className={s.black_date_block}>
                        <img src={require('../../assets/svg/logo.svg').default} alt="logo" />
                        <h4>© 2024, Всегда Даём</h4>
                    </div>
                    <div className={s.constact_block_desktop}>
                        <div className={s.contacts_block}>
                            <div className={s.contact_item}>
                                <img src={require('../../assets/svg/phone.svg').default} alt="phone" />
                                <a href="tel:+7 (495) 940-7355">7 495 940-7355</a>
                            </div>
                            <div className={s.contact_item}>
                                <img src={require('../../assets/svg/contacts_people.svg').default} alt="email" />
                                <a href="mailto:support@vsegda-daem.ru">support@vsegda-daem.ru</a>
                            </div>
                            <div className={s.contact_item}>
                                <img src={require('../../assets/svg/sad_face.svg').default} alt="sad_face" />
                                <Link to="/unsubscribe">Отказаться</Link>
                            </div>
                            <div className={s.contact_item}>
                                <img src={require('../../assets/svg/contact_message.svg').default} alt="message" />
                                <Link to="/contact-us">Связаться с нами</Link>
                            </div>
                        </div>
                        <div className={s.links}>
                            <div className={s.link}><Link to="/#whywe">Почему мы</Link></div>
                            <div className={s.link}><Link to="/personal-account/about-us">О сервисе</Link></div>
                            <div className={s.link}><Link to="/#faq">Вопросы-ответы</Link></div>
                        </div>
                    </div>
                </div>
                <Documents />
            </div>
        </>
    )
}

export default Footer