import React from 'react'
import s from '../style.module.scss'
import Footer from '../../components/Footer/Footer'

const AboutServicePage: React.FC = () => {
    return (
        <>
            <div className={s.container + ' ' + s.container_about} style={{height: '100vh'}}>
                <div className={s.title}>
                    <h1>О СЕРВИСЕ</h1>
                </div>
                <div className={s.content}>
                    <div className={s.content_title}>
                        <h2>Одна заявка во все МФО</h2>
                        <p style={{marginBottom: '8px'}}>Благодаря тесному сотрудничеству с кредитными организациями и большой экспертизе в сфере микрофинансов, сервис предоставляет самые выгодные и актуальные кредитные предложения на рынке.</p> 
                        <p>Для того, чтобы воспользоваться услугой, вам необходимо лишь заполнить анкету на сайте, все остальное сервис сделает за вас, сэкономив вам большое количество времени и сил. Наш сервис работает со всеми клиентами, нам не важно, какая у вас кредитная история и имеются ли текущие просрочки.</p>
                    </div>
                    <div className={s.content_title}>
                        <h2>Принцип работы сервиса:</h2>
                        <ul>
                            <li>Вы заполняете анкету на сайте.</li>
                            <li>По вашим анкетным данным сервис автоматически выбирает наиболее выгодные предложения и работает непосредственно с кредитными организациями, предоставляющими микрозаймы.</li>
                            <li>Ваша заявка будет отправляться в МФО с наибольшей вероятностью одобрения в данный момент времени с соблюдением необходимых пауз между отправками. При предварительном одобрении кредитные организации сами связываются с вами, и вам необходимо лишь подтвердить свое согласие на займ и забрать деньги.</li>
                            <li>Список МФО, в которые была отправлена ваша заявка, и другую необходимую информацию о работе в рамках платной услуги вы можете наблюдать в личном кабинете, в разделе Отчеты.</li>
                            <li>Стоимость услуги - 1 996,00 RUB.</li>
                            <li>Если вы получили займ и более не нуждаетесь в услугах сервиса - вы можете остановить обработку вашей заявкисамостоятельно и в любое время в разделе <span>Отказаться от услуги</span>.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutServicePage