import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const ShortPage: React.FC<{ utmMedium: string | null }> = ({ utmMedium }) => {
    const location = useLocation();
    return (
        <div style={location.pathname === '/' ? { padding: '24px var(--padding-outside-desktop)' } : {}}>
            <Outlet />
        </div>
    );
}

export default ShortPage;