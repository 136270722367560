import React from 'react'
import Card from '../../components/Card/Card'
import s from './style.module.scss'
import Valets from '../../components/Valets/Valets'
import WhyWe from '../../components/WhyWe/WhyWe'
import FAQ from '../../components/FAQ/FAQ'
import Calculateing from '../../components/Calculateing/Calculateing'
import WhiteInfo from '../../components/WhiteInfo/WhiteInfo'
import Footer from '../../components/Footer/Footer'

const Main: React.FC = () => {
  const faqContent = [
    {
      id: 1,
      question: 'Сколько времени уходит на обработку заявки?',
      answer: 'Обработка заявки на займ занимает от нескольких секунд до 15 минут в самых редких ситуациях.'
    },
    {
      id: 2,
      question: 'Как можно погасить взятый займ?',
      answer: `
                 Вы можете вернуть займ наличным
                | С помощью платежа с банковской карты 
                | Посредством банковского перевода.
              `
    },
    {
      id: 3,
      question: 'Каким образом можно получить деньги?',
      answer: 'Вы можете оформить займ на карту, получить наличные или перевести средства на банковский счёт.'
    },
    {
      id: 4,
      question: 'Где доступен наш сервис?',
      answer: 'Мы предлагаем услуги по всей территории Российской Федерации.'
    },
    {
      id: 5,
      question: 'Как прекратить обработку заявки?',
      answer: 'Если вы получили займ и больше не нуждаетесь в нашем сервисе, вы можете отменить обработку заявки в разделе «Отказаться от услуги» или обратиться в службу поддержки. Кроме того, вы можете продолжить использовать сервис, чтобы найти лучшие предложения или оформить несколько займов.'
    },
    {
      id: 6,
      question: 'Сколько же стоит услуга?',
      answer: 'Цена услуги 1999 RUB.'
    },
  ]

  return (
    <div className={s.main} >
      {/* Карточки */}
      <div className={s.info_card_container}>
        <Card img={require('../../assets/svg/card_time.svg').default} title='РЕШЕНИЕ' content='ДО 5 МИНУТ' />
        <Card img={require('../../assets/svg/card_notepad.svg').default} title='92%' content='ОДОБРЕНИЯ' />
        <Card img={require('../../assets/svg/card_hamer.svg').default} title='ПРОДЛЕНИЕ' content='БЕЗ ШТРАФОВ' />
        <Card img={require('../../assets/svg/card_card.svg').default} title='ПЕРЕВОД' content='БЕЗ КОМИССИЙ' />
      </div>
      {/* Синие блоки */}
      <div className={s.info_blue_card_container}>
        <div className={s.blue_card}>
          <div className={s.content_container}>
            <div className={s.circle}>01</div>
            <div className={s.content}>
              <h1>ЗАПОЛНИТЕ АНКЕТУ НА САЙТЕ</h1>
            </div>
          </div>
        </div>
        <div className={s.blue_card}>
          <div className={s.content_container}>
            <div className={s.circle}>02</div>
            <div className={s.content}>
              <h1>ОТПРАВИМ ЗАЯВКИ В ЛУЧШИЕ МФО</h1>
            </div>
          </div>
        </div>
        <div className={s.blue_card}>
          <div className={s.content_container}>
            <div className={s.circle}>03</div>
            <div className={s.content}>
              <h1>ПОЛУЧИТЕ ДЕНЬГИ УДОБНЫМ СПОСОБОМ</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Карты */}
      <div className={s.info_valets_text_container}>
        <div className={s.valets_title}>
          <h1>СПОСОБЫ ПОЛУЧЕНИЯ ЗАЙМА</h1>
          <h3>Visa, Юmoney, МИР, Maestro, QIWI, MasterCard</h3>
        </div>
      </div>
      <div className={s.info_valets_container}>
        <Valets />
      </div>
      {/* Почему мы  */}
      <div className={s.why_we_container} id="whywe">
        <div className={s.why_we_title}>
          <h1>ПОЧЕМУ МЫ?</h1>
          <p>Мы отправим заявки в МФО в нужном порядке и с необходимыми интервалами, чтобы ваши шансы получить займ были максимальны</p>
        </div>
        <div className={s.why_we_content}>
          <WhyWe img={require('../../assets/svg/locker.svg').default} content='Мы предлагаем простой и удобный способ получения денег в кратчайшие сроки' />
          <WhyWe img={require('../../assets/svg//personalRow.svg').default} content='Мы имеем высокий процент одобрения заявок и быструю обработку информации' />
          <WhyWe img={require('../../assets/svg/heart.svg').default} content='Мы всегда готовы помочь вам решить финансовые проблемы и предложить наилучшие условия для займа' />
        </div>
      </div>
      {/* Белый блоки */}
      <div className={s.white_info_block} id="about">
        <WhiteInfo />
        {/* серый блок */}
        <div className={s.gray_info_block} id="faq">
          <div className={s.content_container}>
            <div className={s.white_info_title}>
              <h1>Вопросы-ответы</h1>
            </div>
            <div className={s.white_info_content}>
              {
                faqContent?.map((item, index) => <FAQ key={index} {...item} />)
              }
            </div>
          </div>
        </div>

        {/* блок правил */}
        <div className={s.payment_root}>
          <div className={s.content_container}>
            <div className={s.root_title}>
              <h1>Условия оплаты и безопасность платежей</h1>
              <p>Мы принимаем к оплате карты МИР, VISA и MasterCard. 
                Ваши данные находятся под надежной защитой, и при передаче используется защищённое соединение. 
                Платежи осуществляются с применением технологии 3D-Secure и соответствуют стандартам PCI DSS.
              </p>
            </div>
            <div className={s.root_payment}>
              <img src={require('../../assets/img/assist.png')} alt="assist" />
              <img src={require('../../assets/img/visa.png')} alt="visa" />
              <img src={require('../../assets/img/mir.png')} alt="mir" />
              <img src={require('../../assets/img/nastercard.png')} alt="mastercard" />
            </div>
          </div>
        </div>
      </div>
      <div className={s.calculateing}>
        <Calculateing utm={false}/>
      </div>
      <Footer />
    </div>
  )
}

export default Main