import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import s from './style.module.scss'

export default function Progress() {
    const [progress, setProgress] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 110) {
                    clearInterval(interval)
                    return 100
                }
                return prevProgress + 1
            })
        }, 50)

        const timeout = setTimeout(() => {
            clearInterval(interval)
            setProgress(100)
            navigate('/propositions?utm_medium=cpa')
        }, 6000)

        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        }
    }, [])

    const containerStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem",
        backgroundColor: '#fff',
        borderRadius: "12px",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
    }

    const headerStyle: React.CSSProperties = {
        color: "#ff4d00",
        fontSize: "1.25rem",
        fontWeight: "500",
        marginBottom: "2rem",
        textAlign: "center",
    }

    const percentageStyle: React.CSSProperties = {
        color: "#5b5bf6",
        fontSize: "4rem",
        fontWeight: "bold",
        margin: "0",
    }

    const statusTextStyle: React.CSSProperties = {
        fontSize: "1.5rem",
        color: 'var(--primary-color)',
        fontWeight: "500",
        margin: "1rem 0 2rem 0",
    }

    const progressContainerStyle: React.CSSProperties = {
        width: "100%",
        backgroundColor: "#e5e7eb",
        borderRadius: "9999px",
        position: "relative",
        height: "40px",
    }

    const progressBarStyle: React.CSSProperties = {
        position: "absolute",
        left: 0,
        top: 0,
        height: "40px",
        backgroundColor: "#5b5bf6",
        borderRadius: "9999px",
        width: `${progress}%`,
        transition: "width 0.05s linear",
    }

    const labelsStyle: React.CSSProperties = {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "0.5rem",
        color: "#6b7280",
        fontSize: "0.875rem",
    }


    const containerInitStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: "4rem 2rem",
        backgroundColor: '#fff',
        borderRadius: "12px",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
    }

    const sumStyle: React.CSSProperties = {
        fontSize: "20px",
        color: 'var(--primary-color)',
        fontWeight: "400",
        textAlign: "start",
    }

    return (
        <div className={s.container}>
            {
                progress < 100 ?
                    (
                        <>
                            <div style={headerStyle}>ПРИНИМАЕМ РЕШЕНИЕ ПО ЗАЯВКЕ</div>
                            <div style={containerStyle}>
                                <p style={percentageStyle}>{Math.round(progress)}%</p>
                                <p style={statusTextStyle}>ПРОВЕРЕНО</p>
                                <div style={progressContainerStyle}>
                                    <div style={progressBarStyle} />
                                </div>
                                <div style={labelsStyle}>
                                    <span>0%</span>
                                    <span>100%</span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={headerStyle}>ИДЕНТИФИКАЦИЯ ПОЛЬЗОВАТЕЛЯ</div>
                            <div style={containerInitStyle}>
                                <p style={sumStyle}>Стоимость услуги - 1 996,00 RUB</p>
                            </div>
                        </>
                    )
            }
        </div>
    )
}